<template>
  <v-row class="ma-0">
    <v-col class="q-pa-md relative-position">
      <BaseListLayout
        name="OrganizationList"
        ref="organizationList"
        title="Organization"
        searchable
        :table-header="columns"
        :table-data="organizationList"
        :hasAddButton="true"
        :context="context"
        :total="totalItems"
        localStorageKey="organizationColumns"
        @getList="getOrganizationList"
      >
        <template #addButton>
          <v-btn
            small
            color="primary"
            depressed
            class="text-capitalize"
            @click="addNewOrganization"
          >
            Add Organization
          </v-btn>
        </template>
        <template #dialogs>
          <OrganizationForm
            ref="organizationForm"
            v-model="showOrganizationForm"
            :organizationID="organizationID"
            :editMode="editMode"
            @updateList="updateList"
          />
        </template>
      </BaseListLayout>
    </v-col>
    <OrganizationDetails
      v-model="showOrganizationDetails"
      :organizationDetails="organizationDetails"
      @closeDetails="showOrganizationDetails = false"
    />
  </v-row>
</template>

<script>
import BaseListLayout from "@/components/BaseLayoutComponents/BaseListLayout";
import ActionButton from "@/components/AgGridButtons/ActionButton.vue";
import OrganizationForm from "./OrganizationForm.vue";
import CellRenderer from "@/components/AgGridButtons/CellRenderer.vue";
import OrganizationDetails from "./OrganizationDetails.vue";
import ShipmentCellRenderer from "@/components/AgGridButtons/ShipmentCellRenderer.vue";
import { bus } from "@/main";

export default {
  name: "OrganizationList",
  components: {
    BaseListLayout,
    ActionButton,
    OrganizationForm,
    OrganizationDetails,
    ShipmentCellRenderer,
    CellRenderer,
  },
  data() {
    return {
      fromOrganization: true,
      editMode: false,
      organizationList: [],
      organizationDetails: {},
      showOrganizationForm: false,
      showOrganizationDetails: false,
      organizationID: null,
      totalItems: 0,
      columns: [
        {
          headerName: "Organization Code",
          field: "code",
          minWidth: 150,
          pinned: "left",
        },
        { headerName: "Organization Name", field: "name", minWidth: 150 },
        {
          headerName: "Organization URL",
          field: "url",
          minWidth: 150,
          tooltipField: "url",
        },
        {
          headerName: "API Key",
          field: "api_key",
          minWidth: 150,
          tooltipField: "api_key",
          cellRenderer: "CellRenderer",
        },
        { headerName: "Total Clients", field: "total_clients", minWidth: 100 },
        { headerName: "Total Vendors", field: "total_vendors", minWidth: 100 },
        { headerName: "Total AWBs", field: "total_awbs", minWidth: 100 },
        {
          headerName: "Created On",
          field: "created",
          cellRenderer: "ShipmentCellRenderer",
          minWidth: 180,
        },
        {
          headerName: "Updated On",
          field: "modified",
          minWidth: 180,
          cellRenderer: "ShipmentCellRenderer",
        },
        { headerName: "Created By", field: "created_by", minWidth: 150 },
        { headerName: "Updated By", field: "updated_by", minWidth: 150 },
        {
          headerName: "Actions",
          field: "actions",
          cellRenderer: "ActionButton",
          minWidth: 100,
          pinned: "right",
        },
      ],
    };
  },
  computed: {
    context() {
      return { parentComponent: this };
    },
  },
  methods: {
    getOrganizationList(params) {
      bus.$emit("showLoader", true);
      this.$api.organization
        .getOrganizationList(params)
        .then((res) => {
          bus.$emit("showLoader", false);
          this.totalItems = res.count;
          this.organizationList = res.data.results;
        })
        .catch((err) => {
          console.log(err);

          bus.$emit("showLoader", false);
          bus.$emit("showToastMessage", {
            message: err.data.detail,
            color: "red",
          });
        });
    },
    addNewOrganization() {
      this.editMode = false;
      this.showOrganizationForm = true;
    },
    editDetails(id) {
      this.editMode = true;
      this.organizationID = id;
      this.showOrganizationForm = true;
    },
    viewDetails(id) {
      this.$api.organization
        .viewOrganizationDetails(id)
        .then((res) => {
          this.organizationDetails = res.data;
          this.showOrganizationDetails = true;
        })
        .catch((err) => {
          this.formErrors = err.data;
        });
    },
    openOrganizationApiPage(id, name) {
      this.$router.push({
        name: "organization-api",
        params: { id: id, name: name },
      });
    },
    openOrganizationVendorPage(id, name) {
      this.$router.push({
        name: "organization-vendor",
        params: { id: id, name: name },
      });
    },
    openOrganizationClientPage(id, name) {
      this.$router.push({
        name: "organization-client",
        params: { id: id, name: name },
      });
    },
    openConfiguration(id, name) {
      this.$router.push({
        name: "organization-configuration",
        params: { id: id, name: name },
      });
    },
    updateList() {
      this.$refs.organizationList.updateList();
    },
    copyApiKey(api_key) {
      if (api_key) {
        navigator.clipboard.writeText(api_key);
        bus.$emit("showToastMessage", {
          message: "API Key Copied",
          color: "success",
        });
      }
    },
  },
};
</script>
